@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  overflow-x: hidden;
  background-color: black;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
}

/* Ensure the menu is properly aligned and responsive */
@media (min-width: 768px) {
  .md\:hidden {
    display: none;
  }

  .md\:flex {
    display: flex;
  }
}

.menu-open {
  display: block;
}

/* Additional styling for the profile component */
.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-details {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profile-pic {
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #22c55e;
  width: 6rem;
  height: 6rem;
}

.recent-activity {
  margin-top: 2rem;
}

.activity-item {
  background-color: #374151;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.arrow-animation {
  transition: all 0.2s ease-in-out;
}

.group:hover .arrow-animation {
  transform: rotate(-45deg) scale(1.1);
}

/* Custom hover effects for AuthButtons */
.auth-buttons:hover .login-button:hover + .register-button {
  background-color: #86efac; /* Tailwind color green-400 */
  color: #86efac;
}

.auth-buttons:hover .register-button:hover + .login-button {
  background-color: transparent;
  color: #4ade80; /* Tailwind color green-400 */
}

.custom-switch {
  background-color: #4a4a4a !important; /* Default dark gray color */
}
.custom-switch .ant-switch-checked {
  background-color: green !important;
}
.custom-switch-red .ant-switch-checked {
  background-color: red !important;
}
.custom-switch-green .ant-switch-checked {
  background-color: green !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    255,
    255,
    255,
    0.1
  ); /* Slightly translucent to see the blur effect */
  z-index: 11; /* Ensure it is above all content but below the settings menu */
  pointer-events: none; /* Allows clicks to pass through the overlay */
  backdrop-filter: blur(10px); /* Apply the blur effect */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s;
}

.link {
  transition: all 0.3s ease;
}

.group:hover .link {
  transform: translateX(10px);
}

.arrow-animation {
  transition: all 0.3s ease;
}

.group-hover\:link:hover .arrow-animation {
  transform: translateX(5px) rotate(45deg);
}

.notification {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.notification.fade-in {
  opacity: 1;
}

.gradient-text {
  background: linear-gradient(
    90deg,
    #4ade80,
    #0073ff,
    #835aff,
    #34d399,
    #0073ff,
    #835aff,
    #34d399,
    #0073ff,
    #835aff,
    #34d399,
    #0073ff,
    #835aff,
    #34d399
  );
  background-size: 800% 800%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Sidebar.css */
nav {
  transition: width 0.3s ease, opacity 0.3s ease;
}

nav.w-0 {
  width: 0;
  opacity: 0;
}

/* Topics.jsx */

.category-section {
  margin-bottom: 40px;
}

.category-label {
  font-size: 1.5rem;
  color: #fff;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.category-label.text-left {
  justify-content: flex-start;
}

.category-label.text-right {
  justify-content: flex-end;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.marquee {
  display: flex;
  gap: 20px;
  will-change: transform;
  transition: transform 0.1s linear;
}

.marquee-left {
  direction: ltr;
}

.marquee-right {
  direction: rtl;
}

.marquee-item {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  /* background: #2c2c2c; */
  border-radius: 5px;
  white-space: nowrap;
}

.marquee-item.left-align {
  text-align: left;
}

.marquee-item.right-align {
  text-align: right;
}

.marquee-item p {
  margin: 0;
  color: #eee;
}

.wrapper {
  position: relative;
  margin-top: 5rem;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 1) 7%,
    rgba(0, 0, 0, 1) 93%,
    rgba(0, 0, 0, 0.4)
  );
  border-radius: 1.5rem;
  border: 2px solid #6b7280;
}

.divider {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.5),
    rgba(0, 0, 0, 0)
  );
  margin-top: 20px;
}

.custom-shape-divider-bottom-1733168517 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1733168517 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 123px;
}

.custom-shape-divider-bottom-1733168517 .shape-fill {
  fill: #1f2937;
}

.custom-shape-divider-top-1733168917 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1733168917 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}

.custom-shape-divider-top-1733168917 .shape-fill {
  fill: #1f2937;
}

.custom-shape-divider-top-1733341902 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1733341902 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 191px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1733341902 .shape-fill {
  fill: #1f2937;
}

.custom-shape-divider-bottom-1733342128 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1733342128 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1733342128 .shape-fill {
  fill: #111827;
}

.custom-shape-divider-top-1735112865 {
  position: absolute;

  z-index: 10;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1735112865 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-top-1735112865 .shape-fill {
  fill: #111827;
}

.custom-shape-divider-bottom-1736546061 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1736546061 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 500px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1736546061 .shape-fill {
  fill: #1f2937;
}

.custom-shape-divider-top-1736546609 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1736546609 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 500px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1736546609 .shape-fill {
  fill: #374151;
}

.custom-shape-divider-bottom-1736546061x {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1736546061x svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 500px;
}

.custom-shape-divider-bottom-1736546061x .shape-fill {
  fill: #1f2937;
}

.custom-shape-divider-top-1736546609x {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1736546609x svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 500px;
}

.custom-shape-divider-top-1736546609x .shape-fill {
  fill: #374151;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
