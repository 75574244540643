body,
html {
  overflow-x: hidden;
  background-color: #111827;
  margin: 0;
  padding: 0;
}

.stagger-visualizer {
  display: grid;
  grid-template-columns: repeat(17, 1fr);
  grid-template-rows: repeat(15, 1fr);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

.stagger-visualizer .grid-item {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 2px solid #fff;
  opacity: 3%;
}

.faq-item {
  transition: max-height 0.3s ease-in-out;
}

.faq-item button {
  transition: background-color 0.3s ease-in-out;
}
